'use client';

import { motion, AnimatePresence } from 'framer-motion';
import { Button } from '@/src/app/components/Button/Button';
import { Navigation } from '../Navigation/Navigation';
import { ButtonVariants } from '@/src/app/components/Button/types';
import BurgerMenuIcon from '@/public/icons/burger.svg';
import { modalVariants } from './constants';
import { useMobileMenu } from './hooks/useMobileMenu';
import CrossIcon from '@/public/icons/cross.svg';
import styles from './MobileMenu.module.scss';
import { CommonLink } from '../../../CommonLink/CommonLink';
import { CommonLinkVariants } from '../../../CommonLink/types';
import { Links } from '@/src/app/constants/links';

export const MobileMenu = () => {
  const { isOpen, setIsOpen } = useMobileMenu();

  return (
    <>
      <Button
        variant={ButtonVariants.CLEAR}
        className={styles.burger}
        aria-label='Mobile menu'
        handleClick={() => setIsOpen(true)}
      >
        <BurgerMenuIcon width={42} height={42} fill={'#fff'} />
      </Button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className={styles.mobileMenu}
            initial='hidden'
            animate='visible'
            exit='exit'
            variants={modalVariants}
          >
            <div className={styles.headerMenu}>
              <span>Menu</span>
              <Button variant={ButtonVariants.CLEAR} handleClick={() => setIsOpen(false)}>
                <CrossIcon width={24} height={24} fill={'#fff'} />
              </Button>
            </div>
            <Navigation isMobile />
            <CommonLink variant={CommonLinkVariants.SOLID} link={Links.APP} className={styles.openApp}>
              Open App
            </CommonLink>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
